import { Injectable } from '@angular/core';
import { ApiCommon } from '@shared/apis/common';
import { Observable } from 'rxjs';
import { ParamsBuilder } from '@shared/models/params-builder';

@Injectable({
  providedIn: 'root'
})
export class CouponApi extends ApiCommon {

  getCoupons(pageEvent, isFullCode = false, q?: any, query?: any, params?: any): Observable<any> {
    const builder = new ParamsBuilder();
    builder.pageEvent = pageEvent;
    if (q) {
      builder.q = q;
    }

    if (query) {
      builder.query = query;
    }

    if (params) {
      builder.params = params;
    }

    return this.apiServer.coupon.getCoupons(builder.build(), isFullCode);
  }

  getCoupon(couponId): Observable<any> {
    return this.apiServer.coupon.getCoupon(couponId);
  }

  holdCoupon(params): Observable<any> {
    return this.apiServer.coupon.holdCoupon(params);
  }

  unholdCoupon(params): Observable<any> {
    return this.apiServer.coupon.unholdCoupon(params);
  }

  forceExpire(params): Observable<any> {
    return this.apiServer.coupon.forceExpire(params);
  }

  forceAssign(params): Observable<any> {
    return this.apiServer.coupon.forceAssign(params);
  }

  issueCoupon(params): Observable<any> {
    return this.apiServer.coupon.issueCoupon(params);
  }

  sendCoupon(params): Observable<any> {
    return this.apiServer.coupon.sendCoupon(params);
  }

  changePayment(params): Observable<any> {
    return this.apiServer.coupon.changePayment(params);
  }

  sendFullCoupon(params): Observable<any> {
    return this.apiServer.coupon.sendFullCoupon(params);
  }

  switchCoupon(coupon: any): Observable<any> {
    return this.apiServer.coupon.switchCoupon(coupon);
  }
}
