import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { ComponentId } from '@shared/enums/component-id';
import { ROLE } from '@shared/enums/role';

@Component({
  selector: 'role-permit',
  templateUrl: './role-permit.component.html',
  styleUrls: ['./role-permit.component.scss']
})
export class RolePermitComponent implements OnInit {

  @Input() componentId: any;
  @Input() customClass: any;

  isRole = true;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    switch (this.componentId) {
      case ComponentId.OfferExport:
      case ComponentId.CouponExport:
      case ComponentId.ReportExport:
        this.isRole = this.authService.hasRole([ROLE.ENR_MANAGER, ROLE.COUPON_MANAGER]);
        break;
      case ComponentId.OfferEdit:
      case ComponentId.OfferUpload:
      case ComponentId.CouponEdit:
      case ComponentId.CouponUpload:
      case ComponentId.FullCode:
        this.isRole = this.authService.hasRole([ROLE.COUPON_MANAGER]);
        break;
      case ComponentId.ForceExpire:
      case ComponentId.PaymentChange:
      case ComponentId.PlainTextCouponIssue:
      case ComponentId.AbnormalCaseCouponIssue:
      case ComponentId.AbnormalCaseReviewed:
      case ComponentId.SwitchCoupon:
        this.isRole = this.authService.hasRole([ROLE.ENR_SPECIALIST, ROLE.ENR_MANAGER, ROLE.COUPON_MANAGER]);
        break;
      case ComponentId.ForceAssignment:
        this.isRole = this.authService.hasRole([ROLE.ADMIN]);
        break;
    }

    if (!this.isRole) {
      this.customClass = null;
    }
  }

}
