import { Injectable } from '@angular/core';
import { BaseBackend } from '@shared/apis/servers/base-backend';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CouponBackendServer extends BaseBackend {

  getCoupons(builder, isFullCode): Observable<any> {
    const url = isFullCode ? '/coupon/full-code' : '/coupon';
    return this.get(url, { params: builder });
  }

  getCoupon(couponId): Observable<any> {
    return this.get(`/coupon/${couponId}`);
  }

  forceExpire(params): Observable<any> {
    const { couponId } = params;
    return this.patch(`/coupon/${couponId}/expire`, {});
  }

  forceAssign(params): Observable<any> {
    const { couponId, refNo } = params;
    return this.patch(`/coupon/${couponId}/assign/${refNo}`, {});
  }

  holdCoupon(params): Observable<any> {
    return this.post(`/coupon/hold-coupon`, params);
  }

  unholdCoupon(params): Observable<any> {
    return this.post(`/coupon/unhold-coupon`, params);
  }

  issueCoupon(params): Observable<any> {
    return this.post(`/coupon/issue-coupon`, params);
  }

  sendCoupon(params): Observable<any> {
    return this.post(`/coupon/send-coupon`, params);
  }

  changePayment(params): Observable<any> {
    return this.patch(`/coupon/change-payment`, params);
  }

  sendFullCoupon(params): Observable<any> {
    return this.post(`/coupon/send-fullCoupon`, params);
  }

  switchCoupon(coupon): Observable<any> {
    const { _id: couponId } = coupon;
    return this.patch(`/coupon/switch-fdr-coupon/${couponId}`, coupon);
  }
}
